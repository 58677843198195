
import projectConstants from '~/store/project/-constants'
import guidedTourConstants from '~/store/guidedTour/-constants'

export default {
  name: 'OrganismMenuBarProject',
  data() {
    return {
      isScrolled: false
    }
  },
  computed: {
    i18nActive() {
      return !!this.$store.state.base.meta.generalConfig.i18nActive
    },
    currentProject() {
      const currentProject = this.$store.state.project.projects.find((item) => {
        return item.slug === this.$store.state.project.activeProject
      })
      if (!currentProject) {
        return ''
      }
      return currentProject.slug
    },
    projectOptions() {
      const projects = [...this.$store.state.project.projects]
      return projects.map((project) => {
        return { label: project.name, value: project.slug }
      })
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    showBackButton() {
      return this.$route.params.space
    },
    showProjectSelect() {
      return !this.$route.params.space
    },
    guidedTourModeEnabled() {
      return this.$store.state.guidedTour.guidedTourModeEnabled
    },
    simplifiedView() {
      return this.guidedTourModeEnabled
    }
  },
  mounted() {
    document.addEventListener('scroll', this.initScrollListener)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.initScrollListener)
  },
  methods: {
    handleBackFromSimplifiedView() {
      this.$router.push({
        path: `/project/${this.$route.params.project}`
      })
      this.$store.dispatch(guidedTourConstants.withNamespace('disableGuidedTourMode'))
    },
    initScrollListener() {
      this.isScrolled = window.scrollY > 40
    },
    handleProjectChange(option) {
      if (this.currentProject === option.value) return
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.CHANGE_ACTIVE_PROJECT),
        option.value
      ).then(() => {
        this.$router.push({
          path: `/project/${option.value}`
        })
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    },
  }
}
