
import baseConstants from '../../store/base/-constants'
import requestOfferConstants from "~/store/requestOffer/-constants";
import {smoothScrollToTargetId} from "~/helpers/util";
export default {
  name: 'MoleculeAvailableSpacesTriggerOnMobile',
  props: {
    sections: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].mobileAvailabilityMenuOpen
    }
  },
  methods: {
    showAvailableSpaces() {
      this.scrollTo3DSection()
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_MOBILE_AVAILABILITY_MENU)
      )
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE),
        false
      )
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.CHANGE_CART_SPACES_STATE),
        false
      )
    },
    scrollTo3DSection() {
      const buildingSection = this.sections.find((section) => section.type === 'Building') || {}
      if (buildingSection) {
        smoothScrollToTargetId(`#${buildingSection.hashAnchor}`)
      }
    },
  }
}
