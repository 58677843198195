class BaseApiModule {
  public readonly baseUrl: string

  constructor(url: string) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url
    }
    this.baseUrl = url || 'http://localhost:3000'
  }
}

export default BaseApiModule
