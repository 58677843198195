
export default {
  name: 'AtomLogo',
  props: {
    white: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    resourceUrl() {
      let base = `${this.$store.getters.cdnBase}/`
      if (this.white) {
        if (this.$store.getters.logoImageWhite.includes('://')) base = ''
        return `${base}${this.$store.getters.logoImageWhite}`
      }
      if (this.$store.getters.logoImage.includes('://')) base = ''
      return `${base}${this.$store.getters.logoImage}`
    }
  }
}
