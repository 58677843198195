import { render, staticRenderFns } from "./OrganismMenuBarStatic.vue?vue&type=template&id=55c5c385&"
import script from "./OrganismMenuBarStatic.vue?vue&type=script&lang=js&"
export * from "./OrganismMenuBarStatic.vue?vue&type=script&lang=js&"
import style0 from "./OrganismMenuBarStatic.vue?vue&type=style&index=0&id=55c5c385&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/OrganismMenuBarProject.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Forganisms%2FOrganismMenuBarStatic.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesMoleculeMenuTrigger: require('/app/components/molecules/MoleculeMenuTrigger.vue').default,AtomsCommonAtomLogo: require('/app/components/atoms/common/AtomLogo.vue').default,AtomsCommonAtomLocaleSwitch: require('/app/components/atoms/common/AtomLocaleSwitch.vue').default})
