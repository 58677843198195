
import projectConstants from '~/store/project/-constants'
import guidedTourConstants from '~/store/guidedTour/-constants'

export default {
  name: 'OrganismMenuBarProject',
  data() {
    return {
      isScrolled: false
    }
  },
  computed: {
    i18nActive() {
      return !!this.$store.state.base.meta.generalConfig.i18nActive
    },
    currentProject() {
      const currentProject = this.$store.state.project.projects.find((item) => {
        return item.slug === this.$store.state.project.activeProject
      })
      if (!currentProject) {
        return ''
      }
      return currentProject.slug
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    guidedTourModeEnabled() {
      return this.$store.state.guidedTour.guidedTourModeEnabled
    },
    simplifiedView() {
      return this.guidedTourModeEnabled
    }
  },
  mounted() {
    document.addEventListener('scroll', this.initScrollListener)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.initScrollListener)
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 40
    },
  }
}
