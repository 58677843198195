
import base from '@/store/base/-constants'
export default {
  name: 'OrganismPageLoader',
  data() {
    return {
      percentage: 0,
      seconds: 30,
      maxPercentage: 90,
      interval: 0,
      closeInterval: 0,
      closeTimeRemaining: 10
    }
  },
  computed: {
    isVisible() {
      return this.$store.state.base.pageLoading
    },
    documentReady() {
      return this.$store.state.base.documentIsReady
    }
  },
  watch: {
    isVisible: function (newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.percentage = 0
        this.startLoader()
      }
    }
  },
  mounted() {
    this.startLoader()
  },
  beforeDestroy() {
    clearInterval(this.interval)
    clearInterval(this.closeInterval)
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    },
    startLoader() {
      let ticks = 1
      this.interval = setInterval(() => {
        const percentageChange = this.maxPercentage / this.seconds
        if (
          this.percentage + percentageChange >= this.maxPercentage ||
          this.documentReady ||
          (document && document.readyState === 'complete') ||
          ticks >= 2
        ) {
          console.log(
            '[PageLoader] Loading status: ',
            document ? document.readyState : this.documentReady ? 'complete' : 'loading'
          )
          clearInterval(this.interval)
          if (this.documentReady) this.percentage = 100
          this.triggerLoaderHide()
          this.$store.dispatch(base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE), false)
          return
        }
        ticks += 1
        this.percentage += percentageChange
      }, 1000)
    },
    triggerLoaderHide() {
      setTimeout(() => {
        this.$store.dispatch(base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE), false)
      }, 250)
    }
  }
}
