
import ClickOutside from 'vue-click-outside'
export default {
  name: 'AtomDropdown',
  directives: {
    ClickOutside
  },
  props: {
    titleLabel: {
      required: false,
      type: String,
      default: ''
    },
    selected: {
      required: false,
      type: String || Number,
      default: ''
    },
    options: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      isMenuOpen: false
    }
  },
  computed: {
    currentSelection() {
      if (this.$props.selected) {
        return this.$props.options.find((item) => item.value === this.$props.selected)
      } else {
        return {}
      }
    },
    projectSelectorOptions() {
      return this.$store.state.base.meta.generalConfig.projectSelector
    },
    selectedProjectIcon() {
      return this.projectSelectorOptions.projects[this.currentSelection.value]
        ? this.projectSelectorOptions.projects[this.currentSelection.value].icon
        : ''
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    handleClickOutside() {
      this.isMenuOpen = false
    },
    handleOptionSelect(option) {
      this.isMenuOpen = false
      this.$emit('change', option)
    },
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    },
    getProjectIcon(projectSlug) {
      return this.projectSelectorOptions.projects[projectSlug]
        ? this.projectSelectorOptions.projects[projectSlug].icon
        : ''
    }
  }
}
