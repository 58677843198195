import BaseApiModule from './BaseApiModule'

class LandlordApiModule extends BaseApiModule {
  readonly controller: string

  constructor(url: string) {
    super(url)
    this.controller = 'availability'
  }

  details(buildingCode: string): string {
    const methodUrl = 'project-details'
    return `${this.baseUrl}/${this.controller}/${methodUrl}/${buildingCode}`
  }
}

export default LandlordApiModule
