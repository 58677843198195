import { BaseApiModule, AuthApiModule, LandlordApiModule, AvailabilityApiModule, GuidedTourApiModule } from '~/helpers/api/modules'

export class WebApi {
  public readonly base: BaseApiModule
  public readonly auth: AuthApiModule
  public readonly landlord: LandlordApiModule
  public readonly availability: AvailabilityApiModule
  public readonly guidedTour: GuidedTourApiModule

  constructor(url = '') {
    this.base = new BaseApiModule(url)
    this.auth = new AuthApiModule(url)
    this.landlord = new LandlordApiModule(url)
    this.availability = new AvailabilityApiModule(url)
    this.guidedTour = new GuidedTourApiModule(url)
  }
}

const webApiInstance = new WebApi()

export default webApiInstance
