
import baseConstants from '~/store/base/-constants'
import ClickOutside from 'vue-click-outside'
export default {
  name: 'AtomLocaleSwitch',
  directives: {
    ClickOutside
  },
  components: {},
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    languagesConfig() {
      return this.generalConfig.languages || []
    },
  },
  data() {
    let locale = ''
    let setLang = []
    if (typeof window !== 'undefined') {
      locale = localStorage.getItem('locale')
    }
    if (this.$store.state.base.meta.generalConfig.languages) {
      setLang = this.$store.state.base.meta.generalConfig.languages || []
    } else {
      setLang = [
        {
          locale: 'fi',
          text: 'Finnish'
        },
        {
          locale: 'en',
          text: 'English'
        }
      ]
    }
    return {
      locale: locale || this.$store.state.base.meta.generalConfig.defaultLang || 'en',
      languages: setLang,
      isLanguagesSelectorOpen: false
    }
  },
  mounted() {
    if (this.locale) {
      this.$i18n.locale = this.locale
    }
  },
  watch: {
    locale: function (val) {
      this.$i18n.locale = val
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_LANGUAGE), val)
      localStorage.setItem('locale', val)
      this.$cookiz.set('langCode', val)
    }
  },
  methods: {
    toggleLanguagesOptions() {
      this.isLanguagesSelectorOpen = !this.isLanguagesSelectorOpen
    },
    handleClickOutside() {
      this.isLanguagesSelectorOpen = false
    },
    handleLanguageOption(val) {
      this.isLanguagesSelectorOpen = false
      this.locale = val
    }
  }
}
