
import { isMobile, isTablet } from '~/helpers/mobile/DeviceType'
import { smoothScrollToTargetId } from '~/helpers/util'
import baseConstants from '~/store/base/-constants'
import projectConstants from '~/store/project/-constants'
import requestOfferConstants from "~/store/requestOffer/-constants";

export default {
  name: 'OrganismMenuBarEmbedProject',
  data() {
    return {
      isScrolled: false
    }
  },
  computed: {
    isEmbedV2() {
      return this.$route.path.includes('/embed/v2');
    },
    i18nActive() {
      return !!this.$store.state.base.meta.generalConfig.i18nActive
    },
    currentProject() {
      const currentProject = this.$store.state.project.projects.find((item) => {
        return item.slug === this.$store.state.project.activeProject
      })
      if (!currentProject) {
        return ''
      }
      return currentProject.slug
    },
    projectOptions() {
      const projects = [...this.$store.state.project.projects]
      return projects.map((project) => {
        return { label: project.name, value: project.slug }
      })
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    showBackButton() {
      return this.$route.params.space
    },
    showProjectSelect() {
      return !this.$route.params.space
    },
    sections() {
      const { page } = this.$route.meta
      return page ? page.sections : []
    }
  },
  mounted() {
    document.addEventListener('scroll', this.initScrollListener)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.initScrollListener)
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 40
    },
    handleProjectChange(option) {
      if (this.currentProject === option.value) return
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.CHANGE_ACTIVE_PROJECT),
        option.value
      ).then(() => {
        this.$router.push({
          path: `/embed/${option.value}`
        })
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    },
    handleScrollToTop() {
      this.scrollTo3DSection()
      if (isMobile() || isTablet()) {
        this.$store.dispatch(
          baseConstants.withNamespace(baseConstants.action.TOGGLE_MOBILE_AVAILABILITY_MENU)
        )
        this.$store.dispatch(
          baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE),
          false
        )
        this.$store.dispatch(
          requestOfferConstants.withNamespace(requestOfferConstants.action.CHANGE_CART_SPACES_STATE),
          false
        )
      }
    },
    scrollTo3DSection() {
      const buildingSection = this.sections.find((s) => s.type === 'Building')
      if (buildingSection) {
        smoothScrollToTargetId(`#${buildingSection.hashAnchor}`)
      }
    }
  }
}
